<template>
  <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore" class="">
    <template #fixed>
      <CommonNavigationComponent :title="$t.getTranslation('LBL_SURVEY_LIST')" type="back" :search="false" />
    </template>
    <div class="container survey-content">
      <img class="survey-list-top" :src="require('@/assets/images/survey-bg3.png')" loading="lazy" />
      <SurveyListComponent v-for="survey in list" :key="'survey_' + survey.SurveyKey" :data="survey" />
      <img class="survey-list-bot" :src="require('@/assets/images/survey-bg4.png')" loading="lazy" />
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, inject, onMounted, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
// import CommonNavigationComponent from '@/components/navigations/CommonNavigationComponent.vue'
// import SurveyListComponent from '@/components/SurveyListComponent.vue'

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const SurveyListComponent = defineAsyncComponent(() => import(/* webpackChunkName: "survey-list" */ /* webpackMode: "lazy" */ "@/components/SurveyListComponent.vue"));

export default defineComponent({
  name: "SurveyListPage",
  components: {
    CommonNavigationComponent,
    SurveyListComponent,
  },
  props: {},
  setup(props) {
    let page = 1;
    let size = 5;
    let lastPage = 1;
    const list = ref([]);
    const showPreloader = ref(false);
    const $t = inject("$translation");

    const getData = async () => {
      showPreloader.value = true;
      let ret = await get(`/survey/list?page=${page}&size=${size}`, {
        page: page,
        size: size,
        LanguageCode: $t.getLanguage(),
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          list.value.push(item);
        }
        lastPage = ret?.lastPage;
        page++;
      }
      showPreloader.value = false;
    };

    const loadMore = () => {
      return lastPage >= page ? getData() : false;
    };

    onMounted(async () => {
      await getData();
    });

    return {
      list,
      loadMore,
      showPreloader,
    };
  },
});
</script>

<style>
.survey-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
}
.survey-bg {
  position: absolute;
  width: 100%;
}
.survey-bg img {
  width: 100%;
  height: 100%;
}
.survey-list-top {
  position: absolute;
  top: -15px;
  left: 0px;
  z-index: -1;
  width: 100%;
}
.survey-list-bot {
  position: absolute;
  bottom: 0px;
  z-index: -1;
  width: 100%;
}
</style>
